import { useContext, useEffect, useState } from "react"
import CommonModal from "../../Common/CommonComponents/CommonModal"
import { AuthModalContext } from "../../../Context/AuthModalContext"
import { useDispatch, useSelector } from "react-redux"
import { generateOtp, validateOtp } from "../../../store/Slices/Auth/AuthSlice"
import { useForm } from "react-hook-form"
import TimerCounter from "../../Common/CommonComponents/Counter/TimerCounter"
import { toast } from "react-toastify"
import ButtonLoader from "../Loader/ButtonLoader"

const Login=()=>{
    const {isLoginModalOpen,setIsLoginModalOpen,anyPendingworktoEnd,setAnyPendingWorkToEnd}=useContext(AuthModalContext)
    const [mobile,setMobile]=useState('')
    const [counterVisible, setCounterVisible] = useState(false);
    const [showUserSignupFields,setShowUserSignupFields]=useState(false)
    const [showOtpField,setShowOtpField]=useState(false)
    const dispatch=useDispatch()
    const {register,handleSubmit,reset,setValue,formState:{errors}}=useForm()
    const {token} = useSelector(state=>state.auth)
    const {isLoading}=useSelector(state=>state.loader)

    /* close login modal */
    const closeLoginModal=()=>{
        reset({mobile:'',otp:'',name:'',email:''})
        setIsLoginModalOpen(false)
        setShowOtpField(false)
        setShowUserSignupFields(false)
    }
    // useEffect(()=>{
    //     let interval
    //     if(counter.isVisible){
    //         interval= setInterval(() => {
    //             setCounter((prev) => {
    //                 const newValue = prev.value - 1;

    //                 return newValue >0
    //                     ? { ...prev, value: newValue }
    //                     : { ...prev, isVisible: false };
    //             });
    //         }, 1000);
    //     }
    //     console.log('counter',counter)
    //     return()=>{
    //         clearInterval(interval)
    //     }
    // },[counter.isVisible])
    const startCounter=()=>{
       
    }
    /* send otp api call */
    const formData=new FormData()
    const handleOtpSend=(phoneNo)=>{
        if(phoneNo != null && phoneNo != undefined && phoneNo.length ==10){
            setShowOtpField(true)
            setMobile(phoneNo)
            const getOtp=async()=>{
                // setCounter(pre=>({...pre,value:60}))
                formData.append('mobile',phoneNo)
                const response=await dispatch(generateOtp(formData))
                if(response.status==200){
                    setCounterVisible(true)
                    if(!response.data.isRegisteredUser){
                        setShowUserSignupFields(true)
                    }
                    else{
                        setShowUserSignupFields(false)
                    }
                    setValue('otp',response.data.otp)
                    setValue('mobile',phoneNo)

                }

            }
            getOtp()
        }
    }
    const handlePendingWork = async()=>{
        if (anyPendingworktoEnd) {
            console.log('pending work part')
            await anyPendingworktoEnd();
            setAnyPendingWorkToEnd(null)
          }
    }
    const onSubmit=async(form_data,event)=>{
        console.log('form data',form_data)
        /* formData.append('mobile',formDetails.mobile)
        formData.append('otp',formDetails.otp)
        formData.append('name',form_data.name)
        formData.append('email',form_data.email) */
        const buttonName = event.nativeEvent.submitter.name;

    if (buttonName === "otp_send") {
      handleOtpSend(form_data?.mobile)
    } else if (buttonName === "submit") {
        const response=await dispatch(validateOtp(form_data))
        console.log('login response',response)
        if (response.status == 200) {
            setIsLoginModalOpen(false)
            console.log('pending work',anyPendingworktoEnd,token)
            reset({ otp: '', name: '', email: '' })
            setShowOtpField(false)
            setShowUserSignupFields(false)
            // await handlePendingWork()
        }
        else{
            console.log('error response',response)
            const toastId='wrong_otp'
            if (!toast.isActive(toastId)) {
                toast.error(response.response?.data?.message,
                {
                  toastId,
                  position: "top-right",
                  autoClose: 2000,
                });
              }
        }
    }
    }
    const handleMobileField=()=>{
        setShowOtpField(false)
        setShowUserSignupFields(false)
        reset({otp:'',name:'',email:''})
    }
    return (
        <div>
            <CommonModal isOpenModal={isLoginModalOpen} modalTitle={showUserSignupFields?'Sign Up':'Login'} onClose={closeLoginModal}>
               {/*  <p className="text-crimson mb-3">Please login to continue</p> */}
                <form className="flex flex-col gap-2 p-4" onSubmit={handleSubmit(onSubmit)}>
                <input type='number' placeholder="Enter your mobile number" 
                className={`p-2 rounded-lg border border-custom_grey focus:outline-0 w-full ${showOtpField && 'bg-gray-200 text-gray-400' }`}
                disabled={showOtpField} 
                {...register('mobile')}
                // onChange={(e)=>handleOtpSend(e.target.value)}                
                /> 
                {!showOtpField && <div className="flex justify-center"><button className="bg-crimson text-white p-1 px-3 mt-3 rounded-md custom-button-effect" type='submit' name='otp_send'>Send Otp</button></div>}
                {showOtpField && <div className="flex justify-end mt-0"><span className="text-blue-400 text-[14px] cursor-pointer" onClick={handleMobileField}>change</span></div>}
                {showOtpField &&
                <>
                <input type='number' placeholder="Enter Otp" 
                className="p-2 rounded-lg border border-custom_grey focus:outline-0 w-full"
                {...register('otp',{required:'otp is required'})}/>
                <TimerCounter
                isVisible={counterVisible}
                onComplete={() => setCounterVisible(false)}/>

                {!counterVisible && <div className="flex justify-end"><span className="text-blue-400 cursor-pointer" onClick={()=>handleOtpSend(mobile)}>resend</span></div>}
                </>
                }               
                {showUserSignupFields && 
                <>
                <input type='text' placeholder="Enter name" 
                className="p-2 rounded-lg border border-custom_grey focus:outline-0 w-full" 
                {...register('name',{required:'name is required'})}
                />
                
                <input type='text' placeholder="Enter email" 
                className="p-2 rounded-lg border border-custom_grey focus:outline-0 w-full" 
                {...register('email',{required:'email is required'})}
                />
                </>
                }
                {showOtpField && <button className="border-0 p-2 rounded-lg bg-crimson text-white" type='submit' name='submit'>{isLoading?<div className='flex gap-2 justify-center items-center'><ButtonLoader/> Login</div>:showUserSignupFields?'Sign Up':'Login'}</button>}
                </form>
            </CommonModal>
        </div>
    )
}
export default Login