import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import './CommonModal.css'
const CommonModal=({isOpenModal,onClose,modalTitle,children,modalHeight,scroll,titleStyle})=>{
    useEffect(() => {
        if (isOpenModal) {
            window.scrollTo({top:0,behavior:'smooth'})
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpenModal]);
    console.log('modal height',modalHeight)
    return(
        <div>
          {isOpenModal && (
                <div className={`${isOpenModal ? 'no-scroll':''} modal-backdrop z-10000 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center transition-opacity duration-700 ease-in-out`}>
                    <div className={`custom-modal bg-white z-1000 p-5 rounded-xl shadow-lg transform transition-all duration-[1000ms] ease-in-out ${
              isOpenModal ? "scale-100 opacity-100" : "scale-95 opacity-0"}`} onClick={(e) => e.stopPropagation()}>
                        <div className="flex justify-between gap-7 items-center mb-2"><h2 className={`text-lg font-bold text-[23px] ${titleStyle}`}>{modalTitle}</h2><IoClose style={{padding:3,borderRadius:'50%',background:'#F4F4F4',fontSize:24,cursor:'pointer'}} onClick={onClose}/></div>
                        
                        <main className={`max-h-[80vh] transform transition-transform duration-700 ${
          isOpenModal ? 'translate-y-0' : 'translate-y-full'} ${scroll && 'overflow-scroll scrollbar-hide'}`}>
                            {children}
                        </main>
                       {/*  <button
                            onClick={toggleModal}
                            className="mt-4 px-4 py-2 bg-red-500 text-white rounded"
                        >
                            Close
                        </button> */}
                    </div>
                </div>
            )}  
        </div>
    )
}
export default CommonModal;