import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import Faqs from "../UI/Faqs/Faqs";
import MiniFooter from "../Common/MiniFooter";
import EditProfile from "../UI/Profile/EditProfile";
import PaymentHistory from "../UI/Payment/PaymentHistory";
import TermsAndConditions from "../Common/TermsAndConditions/TermsAndConditions";
import BlogCategories from "../UI/BlogCategories/BlogCategories";
import BlogHomepage from "../UI/Blogs/BlogHomepage";
import BlogInDetails from "../UI/BlogInDetails/BlogInDetails";
const Layout = lazy(() => import('../Common/Layout'));
const HomePage = lazy(() => import('../UI/Home/HomePage'));
const Packages = lazy(() => import('../UI/Packages/Packages'));
const ViewPackageDetails = lazy(() => import('../UI/Packages/ViewPackageDetails'));
const BookingDetails = lazy(() => import('../UI/Bookings/BookingDetails'));
const BookingConfirmation = lazy(() => import('../UI/Bookings/BookingConfirmation'));
const BookingList = lazy(() => import('../UI/Bookings/BookingList'));
const BookedPackageDetails = lazy(() => import('../UI/Bookings/BookedPackageDetails'));
const SavedPackages = lazy(() => import('../UI/Packages/SavedPackage/SavedPackages'));
const Navbar = lazy(() => import('../Common/Navbar'));
const Footer = lazy(() => import('../Common/Footer'));
const Profile = lazy(() => import('../UI/Profile/Profile'));
const AllPackageWithoutFilter = lazy(() => import('../UI/Packages/AllPackageWithoutFilter'));
const Menu = lazy(() => import('../UI/Packages/Menu/Menu'));
const Coupons = lazy(()=>import ('../UI/Coupons/Coupons'));
const PrivacyPolicy = lazy(()=>import ('../Common/PrivacyPolicy/PrivacyPolicy'))

// import Layout from "../Common/Layout";
// import HomePage from "../UI/Home/HomePage";
// import Packages from "../UI/Packages/Packages";
// import ViewPackageDetails from "../UI/Packages/ViewPackageDetails";
// import BookingDetails from "../UI/Bookings/BookingDetails";
// import BookingConfirmation from "../UI/Bookings/BookingConfirmation";
// import BookingList from "../UI/Bookings/BookingList";
// import BookedPackageDetails from "../UI/Bookings/BookedPackageDetails";
// import SavedPackages from "../UI/Packages/SavedPackage/SavedPackages";
// import Navbar from "../Common/Navbar";
// import Footer from "../Common/Footer";
// import Profile from "../UI/Profile/Profile";
// import AllPackageWithoutFilter from "../UI/Packages/AllPackageWithoutFilter";
// import Menu from "../UI/Packages/Menu/Menu";

const ProtectedRoute=()=>{
    const {token}= useSelector(state=>state.auth)
    return token?<Outlet/>:<Navigate to='/' replace/>
}
const Routing=()=>{
 
  const LayoutWithoutNavbar=()=>{
    return(
    <div className="flex flex-col min-h-screen overflow-scroll scrollbar-hide">
      <div className="hidden md:block">
      <Navbar/>
      </div>
      <main className="relative flex-grow pb-[4rem] ">  
      <div className="sm-md:mb-[100px]">
      <Outlet/>
      </div>
      <div className="hidden sm-md:block">
      <MiniFooter/>
      </div>
      </main>
      <Footer/>
    </div>
    );
  }
  
 
   return (
    <Suspense fallback={<div className="flex justify-center items-center absolute top-0 left-0 bg-white opacity-[.5] text-black w-full h-full">Loading...</div>}>
   <Routes>
    <Route path='/' element={<Layout/>}>
    <Route index element={<HomePage/>}/>
    <Route path='packages' element={<Packages/>}/>
    <Route path='terms-conditions' element={<TermsAndConditions/>}/>
    <Route path='policy' element={<PrivacyPolicy/>}/>
    {/* blogs routing */}
    <Route path="/blogs" element={<BlogHomepage />} />
    <Route path="/blog/:slug" element={<BlogInDetails />} />
    <Route path="/category/:slug" element={<BlogCategories />} />


    {/* <Route path='package-details/:id/:oId/:cId' element={<ViewPackageDetails/>}/> */}
    <Route element={<ProtectedRoute/>}>
    </Route>
    </Route>
    <Route path='/' element={<LayoutWithoutNavbar/>}>
    <Route path='package-details/:id/:oId/:cId' element={<ViewPackageDetails/>}/>
    <Route path='menu' element={<Menu/>}/>
    <Route path='menu/:id' element={<Menu/>}/>
    <Route path='package-list' element={<AllPackageWithoutFilter/>}/>
    <Route path='faq' element={<Faqs/>}/>
    <Route path='coupons' element={<Coupons/>}/>
    <Route element={<ProtectedRoute/>}>
    <Route path='saved-package-details/:packageId/:savePackageId' element={<ViewPackageDetails/>}/>
    <Route path='booking/:savedPackageId' element={<BookingDetails/>}/>
    <Route path='booking-list' element={<BookingList/>}/>
    <Route path='saved-package' element={<SavedPackages/>}/>
    <Route path='confirm-booking' element={<BookingConfirmation/>}/>
    <Route path='booking-history/:id' element={<BookedPackageDetails/>}/>
    <Route path='profile/edit-profile' element={<EditProfile/>}/> 
    <Route path='payment-history' element={<PaymentHistory/>}/>    

    </Route>
    <Route path='profile' element={<Profile/>}/>
    </Route>
   </Routes>
    </Suspense>
   );
}
export default Routing;