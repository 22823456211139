import { useState } from 'react'
import reactLogo from './assets/react.svg'
import viteLogo from '/vite.svg'
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
import Routing from './Components/Routing/Routing'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './Components/Common/Util/ScrollToTop'
import { PackageProvider } from './Context/PackageContext'
import { AuthModalProvider } from './Context/AuthModalContext'
import Login from './Components/UI/Authentication/Login'
import { ToastContainer, toast } from 'react-toastify';
import "slick-carousel/slick/slick.css"; // Import slick slider CSS
import "slick-carousel/slick/slick-theme.css";
import Loader from './Components/UI/Loader/Loader'
import { SidebarProvider } from './Context/SidebarContext';
function App() {
    return (
    <>
    <BrowserRouter>
    {/* <Loader/> */}
    <ToastContainer/>
    <SidebarProvider>
    <PackageProvider>
    <AuthModalProvider>
    <ScrollToTop/>
    <Login/>
    <Routing/>
    </AuthModalProvider>
    </PackageProvider>
    </SidebarProvider>
    </BrowserRouter>
    </>
  )
}

export default App
