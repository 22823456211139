import { createContext, useState } from "react";

export const PackageContext=createContext()
export const PackageProvider=({children})=>{
    const [packageType,setPackageType]=useState('veg')
    const [segmentType,setSegmentType]=useState('economy')
    const [guestCount,setGuestCount]=useState(0)
    const [totalPrice,setTotalPrice]=useState({perPlate:0,priceOnGuestNos:0})
    const [totalPackagePrice,setTotalPackagePrice]=useState(0)
    const [selectedOccasion,setSelectedOccasion]=useState('')
    const [selectedLocation,setSelectedLocation]=useState('')
    const [addonItems,setAddOnItems]=useState([])


    const contextValue = {
        packageType, setPackageType, 
        segmentType, setSegmentType, totalPackagePrice, setTotalPackagePrice,
        totalPrice,setTotalPrice,
        guestCount,setGuestCount,
        selectedOccasion,setSelectedOccasion,
        selectedLocation,setSelectedLocation,
        addonItems,setAddOnItems
    }
    return(
        <PackageContext.Provider value={contextValue}>
            {children}
        </PackageContext.Provider>
    )
}