import { createSlice } from "@reduxjs/toolkit";
import { getDataByIdWithAuth, getDataWithAuth, postData, postDataById } from "../../MIddleware/apiMiddleware";
import { setLoader, unsetLoader } from "../Loader/LoaderSlice";

const initialState={
   orderDetails:{},
   bookingList:[],
   bookedPackageDetails:{}
 }
 const orderReducer=createSlice({
     name:'order',
     initialState,
     reducers:{
         storeOrderDetails:(state,action)=>{
             state.orderDetails=action.payload;
         },
         storeBookingList:(state,action)=>{
            state.bookingList = action.payload
         },
         storeBookedPackageDetails:(state,action)=>{
            state.bookedPackageDetails = action.payload
         }
     }
 })
 export  const {storeOrderDetails,storeBookingList,storeBookedPackageDetails}=orderReducer.actions
 const endPoint='place-order'
 export const placeOrder=(savedPackageId,formData,token)=>{
     return async(dispatch)=>{
        dispatch(setLoader())
        try{
         const response=await postDataById(endPoint,savedPackageId,formData,token)
         dispatch(storeOrderDetails(response.data))
         return response
        }catch(err){
            dispatch(unsetLoader())
            return response
        }
     }
 }

 export const getBookingHistory=(token)=>{
    const endPoint='booking-history'
    console.log('from api calling',endPoint)
    return async(dispatch)=>{
        try{
        const response=await getDataWithAuth(endPoint,token)
        dispatch(storeBookingList(response.data))
        console.log('response',response.data)
        return response
        }catch(err){
            dispatch(storeBookingList([]))
            return err
        }
    }
}

export const getBookingDetailsById=(token,id)=>{
    const endPoint='booking-history'
    return async(dispatch)=>{
        const response=await getDataByIdWithAuth(endPoint,id,token)
        dispatch(storeBookedPackageDetails(response.data))
        console.log('response',response.data)
        return response
    }
}
 export default orderReducer.reducer