import { createSlice } from "@reduxjs/toolkit"
import { getData} from "../../MIddleware/apiMiddleware"
import { setLoader, unsetLoader } from "../Loader/LoaderSlice"

const initialState={
    faqs:[]
}
const faqReducer=createSlice({
    name:'faq',
    initialState,
    reducers:{
        storeFaqs:(state,action)=>{
            state.faqs=action.payload
        }
    }
})
export const {storeFaqs}=faqReducer.actions
export const  getFaqs=()=>{
    return async(dispatch)=>{
        const endPoint='faqs'
        dispatch(setLoader())
        try{
        const response=await getData(endPoint)
        dispatch(storeFaqs(response.data))
        dispatch(unsetLoader())
        }catch(err){
            dispatch(unsetLoader())            
            return err
        }
    }
    
}

export default faqReducer.reducer