import { IoArrowBack } from "react-icons/io5"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router"
import { getPaymentHistory, getPaymentReceipt } from "../../../store/Slices/Payment/PaymentSlice"
import { useEffect } from "react"
import CommonContainer from "../../Common/CommonComponents/CommonContainer/CommonContainer"
import { IoMdDownload } from "react-icons/io";
import { CapitalizeWord } from "../../Common/Logic/CommonLogic"
import {hexToPdf, triggerFileDownload } from "../../Common/Logic/HexToPdf/HexToPdf"

const PaymentHistory=()=>{

    const location=useLocation()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const {token} = useSelector(state=>state.auth)
    const {orderId}= location.state || {}
    const {paymentHistory}= useSelector(state=>state.payment)

    useEffect(()=>{
        dispatch(getPaymentHistory(token,orderId))
    },[])
    const handleReceiptDownload=async(id)=>{
        const timeStamp= new Date().getTime()
        try{
        const response=await dispatch(getPaymentReceipt(token,id))
        console.log('response type',response.headers.get('content-type'))
        console.log('response type',response)
         // Check the response content type
      const contentType = response.headers.get("content-type");
      console.log("Response Content-Type:", contentType);

      if (contentType?.includes("application/pdf")) {
        console.log("✅ API is returning a valid PDF file (binary Blob)");
        const blob = await response.blob();
        triggerFileDownload(blob, `payment_receipt_${timeStamp}.pdf`);
        return { success: true }; // Optional: return status to Redux store
      }

      if (contentType?.includes("application/json")) {
        const json = await response.json();
        console.error("Error fetching receipt:", json);
        return rejectWithValue(json);
      }

      if (contentType?.includes("text/plain")) {
        const textData = await response.text();

        // Check if the response is Base64
        if (isBase64(textData)) {
          console.log("📌 API is sending Base64 PDF data");
          base64ToPdf(textData, `receipt_${id}.pdf`);
          return { success: true };
        }

        // Check if the response is Hex
        if (isHex(textData)) {
          console.log("📌 API is sending Hex PDF data");
          hexToPdf(textData, `receipt_${id}.pdf`);
          return { success: true };
        }

        return rejectWithValue({ error: "Invalid response format" });
      }

      return rejectWithValue({ error: "Unsupported response type" });
    } catch (error) {
      console.error("Error fetching PDF:", error);
      return rejectWithValue(error.message);
    }
  }
    
    return (
        <div className="bg-gray-100 md:pt-[5rem] md:px-[5rem]">
            <div className="flex gap-2 items-center px-4 bg-white text-[17px] ">
            <IoArrowBack style={{cursor:'pointer',fontSize:'22px'}} onClick={()=>navigate(`/booking-history/${orderId}`)}/>
            <h2 className="h-[3.5rem] border-b-[1px] border-gray-100 flex items-center font-medium">Payment History</h2>
            </div>
            <div>
                {paymentHistory && paymentHistory.length >0 &&  paymentHistory.map(p=>              
                <CommonContainer customTailwindStyle={'w-[98%] m-auto mt-2 py-2'}>
                    <div className="flex justify-between items-center border-b border-gray-200 text-gray-500 pb-2 text-[12px]">
                    <p className=" leading-none">{p.paymentId}</p>
                    <p >Date:{p.paymentDate}</p>
                    </div>                    
                    <p className="font-medium text-black mt-2">Paid Amount : &#8377;{p.amount}</p>
                    <p className="text-[14px] mt-1">Payment Mode : {p.paymentMethod == 'payment_gateway' ? 'Online':p.paymentMethod}</p>
                    <p className="flex justify-between text-[14px] mt-2 "><button className="flex gap-1 items-center text-blue-600" onClick={()=>handleReceiptDownload(p.id)}><IoMdDownload/>Reciept</button><span className={`${p.status == 'success'?'text-green-500':p.status == 'pending'?'text-orange-500':'text-red-500'}`}>{CapitalizeWord(p.status)}</span></p>

                </CommonContainer>
                )
}
            </div>
        </div>
    )
}
export default PaymentHistory