import axios from "axios";
import { toast } from "react-toastify";
const base_url=import.meta.env.VITE_BASE_URL
const headers={
    Accept: "application/json",
    "Content-Type": "application/json",
}
const getAuthHeaders=(token)=>{
    return {
         'Authorization':`Bearer ${token}`,
        'Content-Type':'application/json'
    }
}
export const getData=async(endPoint,params)=>{
try{
 const response=await axios.get(`${base_url}${endPoint}`,{params:params})
 console.log('response in gateway',response)
 if(response.status==200) return response;
}catch(err){
    const errToasterId=`get-error`
    console.log('error',err)
    if(!toast.isActive(errToasterId)){
        toast.error(err.message,{
            errToasterId,
            autoClose:1000})
    }
    return err;
}
}
export const getDataById=async(endPoint,id)=>{
    try{
        const response=await axios.get(`${base_url}${endPoint}/${id}`)
        if(response.status==200) return response;
       }catch(err){
           console.log('error',err)
       }
}
export const getDataByIdWithAuth=async(endPoint,id,token)=>{
    const headers=getAuthHeaders(token)
    try{
        const response=await axios.get(`${base_url}${endPoint}/${id}`,{headers})
        if(response.status==200) return response;
       }catch(err){
           console.log('error',err)
       }
}
export const getDataWithAuth=async(endPoint,token)=>{
    console.log('from middleware,',endPoint)
    const headers=getAuthHeaders(token)
    try{
        const response=await axios.get(`${base_url}${endPoint}`,{headers})
        if(response.status==200) return response;
       }catch(err){
           console.log('error',err)
       }
}
export const postData=async(endPoint,formData)=>{
    const headers={
        Accept: "multipart/form-data",
        "Content-Type": "application/json",
    }
    try{
        console.log('endpoint',endPoint)
     const response=await axios.post(`${base_url}${endPoint}`,formData)
     if(response.status==200) return response;
    }catch(err){
        console.log('error',err)
        return err
    }
    }

    export const postDataWithAuth=async(endPoint,formData,token)=>{
        const headers=getAuthHeaders(token)
        try{
            console.log('endpoint',endPoint)
         const response=await axios.post(`${base_url}${endPoint}`,formData,{headers})
         if(response.status==200) return response;
        }catch(err){
            console.log('error',err)
            return err
        }
        }

export const postDataById=async(endPoint,id,formData,token)=>{
    const headers=getAuthHeaders(token)
    try{
     const response=await axios.post(`${base_url}${endPoint}/${id}`,formData,{headers})
     if(response.status==200) return response;
    }catch(err){
        console.log('error',err)
    }
}