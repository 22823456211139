import CryptoJS from "crypto-js";

export const roundToTwoDigit=(num)=>{
        return Math.round(num*100)/100
}
export const CapitalizeWord = (word) => {
        if (word !== null && word !== undefined) {
                return word.charAt(0).toUpperCase() + word.slice(1)
        }
        else return word;
}
export const encryptData=(data)=>{
        const secretKey=import.meta.env.VITE_ENCRYPTION_KEY // Store securely (e.g., in environment variables)
        const encrypted= CryptoJS.AES.encrypt(data, secretKey).toString();
        return encrypted.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}
export const removeSpecialChar = (word, char) => {
        if (word !== null && word !== undefined) {
                return word.replace(char, '-')
        }
        else {
                return word
        }
}
export const filterBlogsByCategory = (blogs, category) => {
        return blogs.filter((blog) => blog.category === category);
      };
      
      export const getRecentBlogs = (blogs, count) => {
        return blogs.slice(0, count);
      };