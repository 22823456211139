import { createContext, useState } from "react";

export const AuthModalContext=createContext()
export const AuthModalProvider=({children})=>{
    
    const [isLoginModalOpen,setIsLoginModalOpen]=useState(false)
    const [anyPendingworktoEnd,setAnyPendingWorkToEnd] = useState(null)

    const contextValue={isLoginModalOpen,setIsLoginModalOpen,
        anyPendingworktoEnd,setAnyPendingWorkToEnd
    }
    return(
        <AuthModalContext.Provider value={contextValue}>
            {children}
        </AuthModalContext.Provider>
    )
}