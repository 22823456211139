import { createSlice } from "@reduxjs/toolkit";
import { getData } from "../../MIddleware/apiMiddleware";
import { setLoader, unsetLoader } from "../Loader/LoaderSlice";
const initialState={
    occasions:[],
    locations:[],
    sections:[],
    discounts:[],
    groups:[]
}
const settingsReducer=createSlice({
    name:'settings',
    initialState,
    reducers:{
        storeSettings:(state,action)=>{
            state.occasions=action.payload.occasions;
            state.locations=action.payload.cities;
            state.sections=action.payload.sections;
            state.groups=action.payload.groups;
        },
        storeDiscounts:(state,action)=>{
            state.discounts=action.payload;
        }
    }
})
export  const {storeSettings,storeDiscounts}=settingsReducer.actions
const endPoint='settings'
export const getAllSettings=()=>{
    return async(dispatch)=>{
        // dispatch(setLoader())
        try{
        const response=await getData(endPoint)
        dispatch(storeSettings(response.data))
        // dispatch(unsetLoader())
        }catch(err){
            // dispatch(unsetLoader())
            return err
        }
    }
}
export const getPreApprovedDiscounts=()=>{
    return async(dispatch)=>{
        // dispatch(setLoader())
        try{
        const response=await getData('discount-on-guest-count')
        dispatch(storeDiscounts(response.data))
        // dispatch(unsetLoader())
        }catch(err){
            // dispatch(unsetLoader())
            return err
        }
    }
}
export default settingsReducer.reducer