import { createSlice } from "@reduxjs/toolkit"
import { getData, getDataById, getDataWithAuth, postDataWithAuth } from "../../MIddleware/apiMiddleware"

const initialState={
    couponDetails:{},
    coupons:[],
    userSpecificCoupon:[]
}
const CouponReducer=createSlice({
    name:'coupon',
    initialState,
    reducers:{
        storeCouponDetails:(state,action)=>{
            state.couponDetails=action.payload
        },
        removeCouponDetails:(state)=>{
            state.couponDetails={}
        },
        storeCoupons:(state,action)=>{
            state.coupons=action.payload
        },
        storeUserSpecificCoupon:(state,action)=>{
            state.userSpecificCoupon=action.payload
        }
    }
})
 export const {storeCouponDetails,removeCouponDetails,storeCoupons,storeUserSpecificCoupon}= CouponReducer.actions
const endPoint='apply-coupon'
export const applyCoupon=(formData,token)=>{
    return async(dispatch)=>{
        try{
            const response= await postDataWithAuth(endPoint,formData,token)
            dispatch(storeCouponDetails(response.data))
            return response
        }catch(err){
        }
    }
}

export const getAllCoupons=()=>{
    const endPoint = 'coupons'
    return async(dispatch)=>{
        try{
            const response = await getData(endPoint)
            response.status == 200 && dispatch(storeCoupons(response.data))
            console.log('coupons',response)
            return response
        }catch(err){
            return err
        }
    }
} 
export const getUserSpecificCoupon=(token)=>{
    const endPoint= 'users-coupon'
    return async(dispatch)=>{
        try{
            const response = await getDataWithAuth(endPoint,token)
            response.status == 200 && dispatch(storeUserSpecificCoupon(response.data))
            console.log('coupons',response)
            return response
        }catch(err){
            return err
        }
    }
}
export default CouponReducer.reducer