import React, { useEffect, useState } from "react";

const TimerCounter = ({ isVisible, initialValue = 60, onComplete }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    let interval;
    if (isVisible) {
      setValue(initialValue); // Reset counter when it's made visible
      interval = setInterval(() => {
        setValue((prevValue) => {
          if (prevValue <= 1) {
            clearInterval(interval);
            onComplete(); // Callback when the countdown is complete
            return 0;
          }
          return prevValue - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval); // Cleanup
  }, [isVisible, initialValue, onComplete]);

  return isVisible ? (
    <div className="flex justify-end">
      <span className="text-[12px]">Resend otp in 00:{value < 10 ? `0${value}` : value}</span>
    </div>
  ) : null;
};

export default TimerCounter;
