import { createSlice } from "@reduxjs/toolkit"
import { getData, getDataWithAuth, postDataWithAuth } from "../../MIddleware/apiMiddleware"
import { setLoader, unsetLoader } from "../Loader/LoaderSlice"

const initialState={
    profileData:{}
}
const profileReducer=createSlice({
    name:'profile',
    initialState,
    reducers:{
        storeProfileData:(state,action)=>{
            state.profileData=action.payload
        }
    }    
})
export const {storeProfileData}=profileReducer.actions
const url='profile'
export const getProfileData=(token)=>{
    return async(dispatch)=>{
        try{
            dispatch(setLoader())
            const response=await getDataWithAuth(url,token)
            console.log('profile from action',response.data)
            dispatch(storeProfileData(response.data))
            dispatch(unsetLoader())
        }catch(err){
            dispatch(unsetLoader())
        }
    }
}
export const verifyEmail=(token)=>{
    return async(dispatch)=>{
        const endPoint='email/verify/request'
        try{
            dispatch(setLoader())
            const response=await postDataWithAuth(endPoint,'',token)
            dispatch(unsetLoader())
            console.log('token',response)
            return response
        }catch(err){
            dispatch(unsetLoader())
        }
    }
}
export default profileReducer.reducer