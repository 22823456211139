import { Link } from "react-router-dom"
import logoEvatril from '../../assets/images/logo.png'

const MiniFooter=()=>{
    return(
        <div className="absolute bottom-[40px] w-full bg-gray-100  text-sm text-center py-3 flex flex-col items-center border">
            <img src={logoEvatril} className="h-[26px] w-[80px]"/>
            <div className="pb-2">
                <Link to='policy'>Privacy Policy </Link> |
                <Link to='terms-conditions'> Terms & Conditions</Link> 
            </div>
        </div>
    )
}
export default MiniFooter