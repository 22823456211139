import { createSlice } from "@reduxjs/toolkit"
import { setLoader, unsetLoader } from "../Loader/LoaderSlice"
import { getDataByIdWithAuth, getDataWithAuth, postDataWithAuth } from "../../MIddleware/apiMiddleware"

const initialState={
    paymentDetails:{},
    paymentHistory:[]
}
const PaymentReducer=createSlice({
    name:'payment',
    initialState,
    reducers:{
        storePaymentDetails:(state,action)=>{
            state.paymentDetails=action.payload
        },
        storePaymentHistory:(state,action)=>{
            state.paymentHistory=action.payload
        }
    }
})
export const {storePaymentDetails,storePaymentHistory}=PaymentReducer.actions

const endPoint='create-payment'
export const getRazorPayDetails=(formData,token)=>
{
    return async(dispatch)=>{
        dispatch(setLoader())
        try{
            const response= await postDataWithAuth(endPoint,formData,token)
            dispatch(unsetLoader())
            return response.data
        }catch(err){
            dispatch(unsetLoader())
            return err
        }
    }
}
export const PaymentVerification=(formData,token)=>{
    const endPoint='verify-payment'
    return async (dispatch)=>{
        // dispatch(setLoader())
        try{
            console.log('Sending payment data to the backend...');
            const response = await postDataWithAuth(endPoint,formData,token)
            console.log('getting response from the backend...');
            // dispatch(unsetLoader())
            return response
        }catch(err){
            // dispatch(unsetLoader())
            return err
        }
    }
}
export const getPaymentHistory=(token,id)=>{
    const endPoint='payment-history'
    return async(dispatch)=>{
        try{
            const response = await getDataByIdWithAuth(endPoint,id,token)
            dispatch(storePaymentHistory(response.data))
        }catch(err){
            return err;
        }
    }
}
const base_url=import.meta.env.VITE_BASE_URL

export const getPaymentReceipt=(token,id)=>{
    const endPoint='payment/receipt'
    return async(dispatch)=>{
        try{
            const response = await fetch(`${base_url}${endPoint}/${id}`, {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                  Accept: "application/pdf", // Expecting a PDF response
                },
              });
            // dispatch(storePaymentHistory(response.data))
            return response
        }catch(err){
            return err;
        }
    }
}

export default PaymentReducer.reducer