import { configureStore } from "@reduxjs/toolkit"
import { decryptData, encryptData } from "./Util/Util"
import root from './index'
const saveToLocalStorage=(state)=>{
    try{
    const serializedState=encryptData(state)
    localStorage.setItem('state',serializedState)
    }catch(err){
        console.log('error storing data in local storage')
    }
}
const getFromLocalStorage=()=>{
    const serializedState=decryptData(localStorage.getItem('state'))
    if(serializedState==null) return undefined;
    return serializedState
}
const persistedState=getFromLocalStorage()
const store=configureStore({
    reducer:root,
    preloadedState:persistedState,
})
store.subscribe(()=>saveToLocalStorage(store.getState()))
export default store;