import { combineReducers } from "@reduxjs/toolkit";
import SettingsSlice from './Slices/Settings/SettingsSlice'
import PackageSlice from "./Slices/Package/PackageSlice";
import ItemsSlice from './Slices/Items/ItemsSlice'
import AuthSlice from './Slices/Auth/AuthSlice'
import OrderSlice from './Slices/Order/OrderSlice'
import LoaderSlice from "./Slices/Loader/LoaderSlice";
import ProfileSlice from "./Slices/Profile/ProfileSlice";
import faqSlice from './Slices/Faqs/FaqsSlice';
import CouponSlice from './Slices/Coupon/CouponSlice'
import PaymentSlice from './Slices/Payment/PaymentSlice'
import BlogsSlice from './Slices/Blogs/BlogsSlice'

const rootReducer=combineReducers(
    {
        settings:SettingsSlice,
        package:PackageSlice,
        items:ItemsSlice,
        auth:AuthSlice,
        order:OrderSlice,
        loader:LoaderSlice,
        profile:ProfileSlice,
        faqs:faqSlice,
        coupon:CouponSlice,
        payment:PaymentSlice,
        blogs:BlogsSlice,
    }
)
export default rootReducer;