import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBlogCategories } from "../../../store/Slices/Blogs/BlogsSlice";

const CategoryList = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const {blogs,blogCategories} = useSelector(state=>state.blogs)

  const handleCategory = (slug) => {
    navigate(`/category/${slug}`);
  };
  useEffect(()=>{
    dispatch(getBlogCategories())
  },[])

  console.log('categories',blogCategories)
  return (
    <div className="bg-white p-4 my-10 mx-auto rounded-sm shadow-md animate-fadeIn">
      <h1 className="border-b-2 border-[#ccc] text-[#6C7383] pb-2 mb-1 text-medium font-bold">
        CATEGORIES
      </h1>
      <div className="py-2 px-4">
        {blogCategories.map((category) => (
          <div key={category.id} className="py-1 animate-slideInLeft">
            <li
              className="text-small cursor-pointer"
              onClick={() => handleCategory(category.slug)}
            >
              {category.name}
            </li>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryList;
