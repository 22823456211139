// 
export const hexToPdf =(hexString,id)=>{
    if (!hexString || typeof hexString !== "string") {
        console.error("Invalid input: Hex string is missing or not a string.");
        return;
      }
    
      console.log('hex string',hexString)

      // Step 1: Clean the hex string (remove spaces, new lines, and unnecessary characters)
      hexString = hexString.replace(/\s+/g, "");
    //   console.log('hex string 2nd',hexString)

      console.log("First 8 chars:", hexString.substring(0, 8));  // Should be '25504446'
    console.log("Last 8 chars:", hexString.substring(hexString.length - 8));
        
      // Ensure the string starts with %PDF-1.7 and ends with %%EOF, which are typical for PDF files
      if (!hexString.startsWith('255044462d312e37') || !hexString.includes('2525454f46')) {
        console.error("Invalid hex string: Does not appear to be a valid PDF.");
        return;
      }
    
      // Step 2: Ensure the length is even (each byte = 2 hex characters)
      if (hexString.length % 2 !== 0) {
        console.error("Invalid hex string: length is not even. Attempting to fix...");
        hexString = hexString.slice(0, -1); // Remove the last character to make it even
      }
    
      // Step 3: Convert hex string to binary Uint8Array
      const binaryData = new Uint8Array(
        hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
      );
    
      // Step 4: Create a Blob for the PDF file
      const blob = new Blob([binaryData], { type: "application/pdf" });
    
      // Step 5: Create a download link and trigger download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Receipt_${id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
}
export const triggerFileDownload = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  