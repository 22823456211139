import { createContext, useState } from "react";

export const SidebarContext=createContext()

export const SidebarProvider=({children})=>{
        
    const [showMenuOptions,setShowMenuOptions]=useState(false)

         const contextValue = {
            showMenuOptions,setShowMenuOptions
            }
            return(
                <SidebarContext.Provider value={contextValue}>
                    {children}
                </SidebarContext.Provider>
            )    
}