import { createSlice } from "@reduxjs/toolkit"
import { getData } from "../../MIddleware/apiMiddleware"

const initialState={
    blogs:[],
    blogCategories:[],
    blogDetails:{},
    currentBlog:{},
}
const blogReducer=createSlice({
    name:'blog',
    initialState,
    reducers:{
        storeBlogs:(state,action)=>{
            state.blogs=action.payload
        },
        storeBlogCategories:(state,action)=>{
            state.blogCategories=action.payload
        },
        storeBlogDetails:(state,action)=>{
            state.blogDetails=action.payload
        },
        storeCurrentBlogDetails:(state,action)=>{
            state.currentBlog = action.payload
        }
    }
})
export const {storeBlogs,storeBlogCategories,storeBlogDetails,storeCurrentBlogDetails}=blogReducer.actions


export const getBlogCategories=()=>{
    const endPoint='blog-categories'
    return async(dispatch)=>{
        try{
            const response = await getData(endPoint)
            dispatch(storeBlogCategories(response.data))
            return response
        }catch(err){
            return err
        }finally{

        }
    }
}

export const getBlogs=()=>{
    const endPoint='blogs'
    return async(dispatch)=>{
        try{
            const response = await getData(endPoint)
            console.log('blogs',response.data)
            dispatch(storeBlogs(response.data))
            return response
        }catch(err){
            return err
        }finally{

        }
    }
}
export const getBlogDetails=(slug)=>{
    const endPoint=`blogs/${slug}`
    return async(dispatch)=>{
        try{
            const response = await getData(endPoint)
            dispatch(storeBlogDetails(response.data))
            return response
        }catch(err){
            return err
        }finally{

        }
    }
}
export default blogReducer.reducer