import { IoArrowBack } from "react-icons/io5"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import CommonContainer from "../../Common/CommonComponents/CommonContainer/CommonContainer"
import CommonModal from "../../Common/CommonComponents/CommonModal"
import { useEffect, useRef, useState } from "react"
import { generateOtpForMobileChange, updateMobile } from "../../../store/Slices/Auth/AuthSlice"
import { getProfileData, verifyEmail } from "../../../store/Slices/Profile/ProfileSlice"
import { MdDone } from "react-icons/md";
import { toast } from "react-toastify"


const EditProfile=()=>{
    const inputCss="border border-gray-400 rounded-md p-2 w-full"
    const { token} = useSelector(state => state.auth)
    const {profileData}= useSelector(state=>state.profile)
    const [showEditModal,setShowEditModal]=useState({state:false,type:''})
    const [showOtpField,setShowOtpField]=useState(false)
    const [showError,setShowError]=useState({state:false,message:''})
    const mobileRef=useRef()
    const otpRef=useRef()
    const dispatch=useDispatch()

    const navigate=useNavigate()


    useEffect(()=>{
        dispatch(getProfileData(token))
    },[])
    const openEditModal=(type)=>{
        setShowEditModal({state:true,type:type})
    }
    const closeEditModal=()=>{
        setShowEditModal({state:false,type:''})
        setShowOtpField(false)
        setShowError({state:false,message:''})

    }
    const handleMobileInput=()=>{
        setShowError({state:false,message:''})
    }
    const fecthProfile=()=>{
        setShowEditModal({state:false,type:''})
        dispatch(getProfileData(token))
    }
    const emailVerify=()=>{
        const response=dispatch(verifyEmail(token))
        response.status == 200 && toast.success(response.data.message)
    }
    const handleOtpSend=async()=>{        
        const mobileNo=mobileRef.current.value;
        const formdata = new FormData()
        formdata.append('mobile',mobileNo)
        if(!showOtpField){
            // send otp api call
        const response=await dispatch(generateOtpForMobileChange(formdata,token))
        console.log('response',response)
        response?.status==200 && setShowOtpField(true)
        response?.status == 400 && setShowError({state:true,message:response.response.data.message})
        }
        // mobile update api call
        else{
            const otp= otpRef.current.value
            formdata.append('otp',otp)
            const response = await dispatch(updateMobile(formdata,token))
            response.status == 200 && fecthProfile()
            response.status == 400 && setShowError({state:true,message:response.response.data.message})
        }
    }
    return(
        <div className="md:mt-[3.5rem] md:mx-[7rem]">
        <div className="flex gap-2 sm-md:px-4 items-center my-3 sm-md:fixed sm-md:bg-white sm-md:w-full sm-md:my-0 sm-md:h-[3.5rem] sm-md:border-b-[1px] border-gray-100 mb-2 sm-md:text-[16px] font-medium">
            <IoArrowBack style={{background:'white',fontSize:22,cursor:'pointer'}} onClick={()=>navigate(-1)}/><span >Edit Profile</span></div>            
        <div className="flex flex-col gap-2 justify-center pt-[5rem] m-auto w-[90%] items-center">           
           
            <label for='email' className="text-gray-400 self-start"> Email:</label>
            <div className="flex gap-2 items-center w-full border border-gray-100 rounded-md bg-gray-100">
            <input type='email' name='email' value={profileData.email} disabled className=" p-2 w-full "/>
            {profileData.emailVerifiedAt != null && <div className="text-green-500 flex gap-1 items-center sm-md:text-[12px] "><MdDone/> Verified</div>} 
            </div>
            {profileData.emailVerifiedAt == null && <div className="flex self-end"><button className="sm-md:text-[12px] text-blue-500" onClick={emailVerify}>Verify Email</button></div> }        
            <label for='name' className="text-gray-400 self-start"> Name:</label>
            <input type='text' name='name' value={profileData.name}  disabled className="border border-gray-100 rounded-md p-2 w-full bg-gray-100"/>
            
            <label for='mobile' className="text-gray-400 self-start"> Mobile:</label>
            <input type='number' name='mobile' value={profileData.mobile}  disabled className="border border-gray-100 rounded-md p-2 w-full bg-gray-100"/>
            <div className="flex self-end sm-md:text-[12px] cursor-pointer" ><span onClick={()=>openEditModal('mobile')}>Change Mobile No.</span></div>
            
        </div>
        <CommonModal isOpenModal={showEditModal.state} onClose={closeEditModal} modalTitle={'Update Mobile'} titleStyle='text-gray-500'>
            <input type={showEditModal.type == 'mobile'?'number':showEditModal.type == 'email'?'email':'text'} ref={mobileRef} onChange={handleMobileInput} className={inputCss}/>
            {(showError.state && !showOtpField) && <p className="text-red-500 text-[14px]">{showError.message}</p>}
            {showOtpField && <input type='number' ref={otpRef} className={`${inputCss} mt-3`} placeholder="OTP" />}
            {showError.state && <p className="text-red-500 text-[14px]">{showError.message}</p>}
            <div className="flex justify-end"><button className="p-1 px-3 bg-crimson text-white rounded-md mt-2" onClick={handleOtpSend}>{showOtpField ?'Update Mobile':'Send Otp'} </button></div>
        </CommonModal> 
        </div>
    )
}
export default EditProfile