import { useEffect, useState } from "react"
import { getFaqs } from "../../../store/Slices/Faqs/FaqsSlice"
import { useDispatch, useSelector } from "react-redux"
// icon imports
import { MdOutlineNavigateNext } from "react-icons/md";
import { PiWechatLogoBold } from "react-icons/pi"
import { MdOutlineExpandMore } from "react-icons/md";
import { IoArrowBack, IoEye } from "react-icons/io5";
import { useNavigate } from "react-router";

const Faqs=()=>{
    const { faqs } = useSelector(state => state.faqs)

    const [isQuestionDrawerOpen, setIsQuestionDrawerOpen] = useState(Array(faqs?.length).fill(false))
    
    const dispatch = useDispatch()
    const navigate=useNavigate()
    useEffect(() => {
        dispatch(getFaqs())
    }, [])

    const handleQuestionDrawerOpen=(id)=>{
        setIsQuestionDrawerOpen(pre=>pre.map((item,index)=>(id==index?!item:item)))
    }
    return(
        <div>
            <div className='md:mt-[5rem] md:flex justify-between items-center md:px-[7rem]  '>
                        <div className='md:w-[50%] flex md:flex-col items-center'>
                            <p className='hidden md:block text-[42px] text-bold'>Frequently <br/> asked questions</p>
                            <p className='flex gap-3 md:hidden w-full text-[18px] font-medium items-center px-4 h-[3.5rem] flex items-center border-b-[3px] border-gray-100' style={{cursor:'pointer'}} onClick={()=>navigate(-1)}><IoArrowBack />FAQs</p>
                            <p className='hidden md:flex items-center text-[16px] gap-4 '><PiWechatLogoBold/>Still need help? Contact us.</p>
                        </div>
                        <div className='md:w-[50%]'>
                            {faqs?.length>0 && faqs.map((q,index) => (
                                <div key={q.id} className={`${isQuestionDrawerOpen[index]?'bg-gray-100':''} px-4`}>
                                    <h4 className="flex items-center py-2 cursor-pointer gap-2 font-medium custom-button-effect-white" onClick={() => handleQuestionDrawerOpen(index)}>
                                        <MdOutlineNavigateNext className='next-arrow' style={{transition: "transform 0.5s ease-in-out",transform: isQuestionDrawerOpen[index] ? "rotate(90deg)" : "rotate(0deg)",}}/><p className=''>{q.question}</p>
                                    </h4>
                        <div className={`overflow-hidden transition-all duration-1000 ease-in-out ${isQuestionDrawerOpen[index] ? "[max-height:500px] opacity-100" : "[max-height-10px] opacity-0"}`}>

                                    {isQuestionDrawerOpen[index] &&
                                        (
                                            <p className='text-[1rem] sm-md:text-[14px] px-5'>{q.answer}</p>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                            <p className='hidden sm-md:flex absolute items-center text-[16px] gap-4 justify-center pt-2 px-4'><PiWechatLogoBold/>Still need help? Contact us.</p>
                            
                    </div>
        </div>
    )
}
export default Faqs