import { createSlice } from "@reduxjs/toolkit";
import { getData, getDataById } from "../../MIddleware/apiMiddleware";

const initialState={   
   items:[]
}
const itemsReducer=createSlice({
    name:'items',
    initialState,
    reducers:{
        storeItems:(state,action)=>{
            state.items=action.payload;
        },
    }
})
export  const {storeItems}=itemsReducer.actions
const endPoint='items'
export const getAllItems=()=>{
    return async(dispatch)=>{
        const response=await getData(endPoint)
        console.log('response',response.data)
        dispatch(storeItems(response.data))
        return response
    }
}
export default itemsReducer.reducer