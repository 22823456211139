import React, { useEffect, useState } from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useParams } from "react-router-dom";
import BlogTheme1 from "../../Common/CommonComponents/BlogLayouts/BlogTheme1";
import BlogTheme2 from "../../Common/CommonComponents/BlogLayouts/BlogTheme2";
import RelatedBlogs from "../Blogs/RelatedBlogs";
import RecentBlogs from "../Blogs/RecentBlogs";
import BlogCategories from "../BlogCategories/BlogCategories";
import CategoryList from "../Blogs/CategoryList";
import { useDispatch, useSelector } from "react-redux";
import { storeCurrentBlogDetails } from "../../../store/Slices/Blogs/BlogsSlice";

const socialMedia = [
  {
    name: "Facebook",
    icon: FaFacebookF,
    color: "bg-blue-600",
    hoverColor: "hover:bg-blue-700",
    ShareButton: FacebookShareButton,
  },
  {
    name: "Twitter",
    icon: FaTwitter,
    color: "bg-blue-400",
    hoverColor: "hover:bg-blue-500",
    ShareButton: TwitterShareButton,
  },
  {
    name: "LinkedIn",
    icon: FaLinkedinIn,
    color: "bg-blue-800",
    hoverColor: "hover:bg-blue-800",
    ShareButton: LinkedinShareButton,
  },
  {
    name: "Whatsapp",
    icon: FaWhatsapp,
    color: "bg-green-500",
    hoverColor: "hover:bg-green-600",
    ShareButton: WhatsappShareButton,
  },
];


const BlogInDetails = () => {
//   const { blogs, loading, setCurrentBlog } = useBlogContext();
  const { slug } = useParams();
  const [currentBlog,setCurrentBlog]=useState({})
  const {blogs,blogCategories} = useSelector(state=>state.blogs)
  const blog = blogs?.find((blog) => blog.slug === slug);
  const dispatch= useDispatch()

  // if (loading) return <div>Loading...</div>;
  // if (!blog) return <div>Blog not found</div>;

  useEffect(()=>{
    dispatch(storeCurrentBlogDetails(blog));

  })
  const currentPageUrl = window.location.href;

  return (
    <div className=" mx-auto px-4 md:px-24 my-[4rem] sm-md:my-[3.5rem] py-2 animate-fadeIn border-t border-gray-200">
      <h1
        className="text-3xl text-center py-3 "
        dangerouslySetInnerHTML={{ __html: blog.title }}
      ></h1>
      <p className="text-center pb-10 text-sm text-[#6C7383]">Posted on {blog.postedOn}</p>
      <div className="grid grid-cols-1 md:grid-cols-10 gap-12">
        <div className="col-span-1 md:col-span-7">
          {blog.theme === 1 && <BlogTheme1 blog={blog} />}
          {blog.theme === 2 && <BlogTheme2 blog={blog} />}
          <div className="flex flex-col md:flex-row justify-center md:justify-start space-y-2 md:space-y-0 md:space-x-4 md:py-3 md:my-10">
            {socialMedia.map(({ name, icon: Icon, color, hoverColor, ShareButton }) => (
              <ShareButton
                key={name}
                url={currentPageUrl}
                className={`flex items-center space-x-2 ${color} text-white rounded-sm ${hoverColor} hover:scale-110 duration-300`}
                style={{backgroundColor: color, color: "white", padding: "0.7rem 2rem"}}
                title={`Share on ${name}`}
              >
                <Icon /> <span>{name}</span>
              </ShareButton>
            ))}
          </div>
        </div>
        <div className="col-span-1 md:col-span-3">
          <RecentBlogs />
          <CategoryList/>
        </div>
      </div>
          <RelatedBlogs />
    </div>
  );
};

export default BlogInDetails;
