import { createSlice } from "@reduxjs/toolkit";
import { getData, getDataById, getDataWithAuth, postDataById } from "../../MIddleware/apiMiddleware";
import { setLoader, unsetLoader } from "../Loader/LoaderSlice";

const initialState={
   packageDetails:{},
   packages:[],
   totalPrice:0,
   savedPackages:[],
   savedPackageId:'',
   selectedOccLoc:{location:'',occasion:''},
   packageType:'',
}
const packageReducer=createSlice({
    name:'package',
    initialState,
    reducers:{
        storePackages:(state,action)=>{
            state.packages=action.payload;
        },
        storePackageDetails:(state,action)=>{
            state.packageDetails=action.payload.hasOwnProperty('packageData')?action.payload.packageData:action.payload
            state.totalPrice=action.payload.price
            state.savedPackageId=action.payload.uuid
        },
        storeSavedPackages:(state,action)=>{
            state.savedPackages=action.payload
        },
        removeSavedPackageid:(state)=>{
            state.savedPackageId=''
        },
        storeSelecteOccLoc:(state,action)=>{
            state.selectedOccLoc={location:action.payload.location,occasion:action.payload.occasion}
        },
        removeSelectedLocOcc:(state)=>{
            state.selectedOccLoc={location:'',occasion:''}
        },
        storePackageType:(state,action)=>{
            state.packageType=action.payload
        },
    }
})
export  const {storePackages,storePackageDetails,storeSavedPackages,removeSavedPackageid,storeSelecteOccLoc,removeSelectedLocOcc,storePackageType}=packageReducer.actions
const endPoint='package'
export const getAllPackages=(paramsData)=>{
    return async(dispatch)=>{
        dispatch(setLoader())
        try{
        const response=await getData(endPoint,paramsData)
        dispatch(storePackages(response.data))
        dispatch(unsetLoader())
        return response
        }catch(err){
            return err
        }        
}
}
export const getPackageDetailsById=(id,savedPackageId)=>{
    return async(dispatch)=>{
        // dispatch(setLoader())
        const response=await getDataById(endPoint,savedPackageId?`${id}/${savedPackageId}`:id)
        console.log('response',response.data)
        // dispatch(unsetLoader())
        dispatch(storePackageDetails(response.data))
        return response
    }
}

export const savePackage=(id,formData,token)=>{
    return async(dispatch)=>{
        const response=await postDataById(endPoint,id,formData,token)
        console.log('response',response?.data)
        dispatch(storePackageDetails(response.data))
        return response
    }
}
export const getSavedPackageDetails=(token)=>{
    const endPoint='saved-package-history'
    return async(dispatch)=>{
        console.log('saved package',token)
        // dispatch(setLoader())
        try{
        const response=await getDataWithAuth(endPoint,token)
        console.log('response',response.data)
        // dispatch(unsetLoader())
        dispatch(storeSavedPackages(response.data))
        return response
        }
        catch(err){
            dispatch(storeSavedPackages([]))
            // dispatch(unsetLoader())
            return err
        }
    }
}

export default packageReducer.reducer